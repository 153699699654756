
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import { Api } from "@/assets/Api";

export default defineComponent({
  name: "ConfigWXMenu",
  components: {},
  setup() {
    let data = reactive<any>({
      formLabelWidth: "140px",
      offi: [],
      formSearch: {},
      update: false,
      menu: "",
    });

    const GetWXOffi = () => {
      Api.Api2.WXOffi().then((res: any) => {
        console.info(res);
        if (res.code == 0) {
          data.offi = res.data;
        }
        console.info(data.offi);
      });
    };
    const GetWXMenu = () => {
      if (!data.formSearch.AppID) return Api.Msg.warning("请先选择公众号");
      Api.Api2.WXMenu(data.formSearch.AppID).then((res: any) => {
        if (res.code == 0) {
          data.menu = JSON.stringify(res.data, null, 4);
          data.update = false;
        } else {
          Api.Msg.warning(res.data);
        }
        console.info(res);
      });
    };
    const updateWXMenu = () => {
      if (!data.menu) return Api.Msg.warning("请设置菜单内容");
      Api.Api2.CreateWXMenu(data.formSearch.AppID, JSON.parse(data.menu)).then(
        (res: any) => {
          if (res.code == 0) {
            data.update = false;
          }
          Api.Msg.warning(res.data);
          console.info(res);
        }
      );
    };

    onMounted(() => {
      GetWXOffi();
    });

    return {
      ...toRefs(data),
      GetWXOffi,
      updateWXMenu,
      GetWXMenu,
    };
  },
});
