import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_form, {
              inline: true,
              model: _ctx.formSearch,
              class: "demo-form-inline"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: "公众号",
                  "label-width": _ctx.formLabelWidth
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      modelValue: _ctx.formSearch.AppID,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formSearch.AppID) = $event)),
                      placeholder: "请选择公众号"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.offi, (item) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: item.AppID,
                            label: item.AppName,
                            value: item.AppID
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 8, ["label-width"]),
                _createVNode(_component_el_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_button, {
                      type: "primary",
                      onClick: _ctx.GetWXMenu
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("载入菜单")
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_form_item, null, {
                  default: _withCtx(() => [
                    (_ctx.update)
                      ? (_openBlock(), _createBlock(_component_el_button, {
                          key: 0,
                          type: "primary",
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateWXMenu()))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("保存修改")
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, {
          offset: 1,
          span: 22
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              type: "textarea",
              autosize: { minRows: 10 },
              placeholder: "请输入内容",
              modelValue: _ctx.menu,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.menu) = $event)),
              onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.update = true))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}